
























import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { MyPageViewModel } from '../../viewmodels/my-page-viewmodel'

@Observer
@Component({
  components: {
    'all-post-list': () => import('../my-page/all-post-list/all-post-list.vue'),
    observer: () => import('vue-intersection-observer'),
    'post-grid': () => import('@/modules/community/common/components/post-grid.vue'),
  },
})
export default class AllPost extends Vue {
  @Inject() vm!: MyPageViewModel
  loadMore(entry) {
    if (this.vm.allPostsCanLoadMore) {
      if (entry.isIntersecting) this.vm.allPostsLoadMore()
    }
  }
}
